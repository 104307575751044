<script>
/* COMPONENT DOCUMENT
 * author: liyulong
 * date: 2019/10/08
 * desc: 三级联动 - 市区村地址选择
 */

// import Vue from 'vue';
import Vue from 'vue';
import {  Select, Option, Message } from 'element-ui';
Vue.use(Select);
Vue.use(Option);
export default {
  name: 'city-district-village',
  props: {
    // title: { type: String, default: '基地地址' },
    // disabled: { type: Boolean, default: false }, // 只为了显示不能选择地址
    // cityId: { type: String, default: '' },
    // districtId: { type: String, default: '' },
    // villageId: { type: String, default: '' },
    cityDistrictVillage: {type: Object, default: () => {} }
  },
  watch: {
    cityDistrictVillage: {
      async handler(val) {
        if (val) {
          this.disabled = val.disabled;
          if (val.cityId && val.districtId ){
            // this.getAddressName();
            await this.getCityInfo('PROVINCE', '', false);
            await this.getCityInfo('CITY', val.proviceId, false);
            await this.getCityInfo('DISTRICT', val.cityId, false);
            await this.getCityInfo('VILLAGE', val.districtId, false);
          } else if (!val.cityId && !val.districtId) {
             await this.getCityInfo('PROVINCE', '');
          }
        }
      },
      immediate: true
    }
  },

  data() {
    return {
      provice: '',
      city: '',
      district: '',
      area: '',
      proviceList: [],
      cityList: [],
      districtList: [],
      areaList: [],
      message: '',
      val: []
    };
  },

  methods: {
    /** 获取市区村地址 */
    async getCityInfo(level, id, isEchoDisplay) {
      const api = this.$fetchApi.getCityList.api;
      const data = await this.$fetchData.fetchPost({ level, parentId: id }, api, 'json');
      if (data.code === '200') {
        if (level === 'PROVINCE') { // 市
          this.proviceList = data.result;
          if (this.cityDistrictVillage && this.cityDistrictVillage.provinceId && !isEchoDisplay) {
            data.result.forEach(item => {
              if (item.id === this.cityDistrictVillage.provinceId) {
                this.provice = item;
                this.val[0] = {
                  id: item.id,
                  name: item.name
                };
              }
            });
          }
        }  else
        if (level === 'CITY') { // 市
          this.cityList = data.result;
          if (this.cityDistrictVillage && this.cityDistrictVillage.cityId && !isEchoDisplay) {
            data.result.forEach(item => {
              if (item.id === this.cityDistrictVillage.cityId) {
                this.city = item;
                this.val[1] = {
                  id: item.id,
                  name: item.name
                };
              }
            });
          }
        }  else if (level === 'DISTRICT') { // 区
          this.districtList = data.result;
          if (this.cityDistrictVillage && this.cityDistrictVillage.districtId && !isEchoDisplay) {
            data.result.forEach(item => {
              if (item.id === this.cityDistrictVillage.districtId) {
                this.district = item;
                this.val[2] = {
                  id: item.id,
                  name: item.name
                };
              }
            });
          }
        } else { // 村
          const areaList = data.result;
          this.areaList = areaList;
          if (this.cityDistrictVillage && this.areaList.length > 0 && !isEchoDisplay) {
            this.areaList.unshift({ name: '全部', id: '' });
            if (this.cityDistrictVillage.villagesId) {
              data.result.forEach(item => {
                if (item.id === this.cityDistrictVillage.villagesId) {
                  this.area = item;
                  this.val[3] = {
                    id: item.id,
                    name: item.name
                  };
                }
              });
            } else if (this.cityDistrictVillage.villagesId === '') {
              this.area = {id: '', name: '全部'};
              this.val[3] = this.area;
            }
            
            this.$emit('villageInfo', this.val);
          }
        }
      } else {
        // Message({
        //   showClose: true,
        //   message: data.message,
        //   type: 'error'
        // });
      }
    },
    // 选择时
    async choseProvince(e) {
      for (const index in this.proviceList) {
        if (e.id === this.proviceList[index].id) {
          await this.getCityInfo('CITY', this.proviceList[index].id, true);
          this.district = '';
          this.area = '';
          this.val[0] = {
            id: this.proviceList[index].id,
            name: this.proviceList[index].name
          };
        }
      }
    },
    // 选择时
    async choseCity(e) {
      for (const index in this.cityList) {
        if (e.id === this.cityList[index].id) {
          await this.getCityInfo('DISTRICT', this.cityList[index].id, true);
          this.district = '';
          this.area = '';
          this.val[1] = {
            id: this.cityList[index].id,
            name: this.cityList[index].name
          };
        }
      }
    },
    // 选区
    async choseDistrict(e) {
      for (const index in this.districtList) {
        if (e.id === this.districtList[index].id) {
          this.district = this.districtList[index];
          await this.getCityInfo('VILLAGE', this.districtList[index].id, true);
          if (this.areaList.length > 0) {
            this.areaList.unshift({ name: '全部', id: '' });
          }
          this.area = {};
          this.val[2] = {
            id: this.districtList[index].id,
            name: this.districtList[index].name
          };
        }
      }
    },
    // 选村
    choseBlock(e) {
      for (const index in this.areaList) {
        if (e.id === this.areaList[index].id) {
          this.area = this.areaList[index];
          this.val[3] = {
            id: this.areaList[index].id,
            name: this.areaList[index].name
          };
        }
      }
      this.$emit('villageInfo', this.val);
    }
  }
};
</script>

<template>
<div class="city_district_village flex">
  <div class='areaItem'>
    <el-select
      v-model="provice"
      value-key="id"
      @change="choseProvince" 
      placeholder="省级地区">
      <el-option
        v-for="item in proviceList"
        :key="item.name"
        :label="item.name"
        :value="item">
      </el-option>
    </el-select>
  </div>
  <div class='areaItem'>
    <el-select
      v-model="city"
      value-key="id"
      @change="choseCity" 
      placeholder="市级地区">
      <el-option
        v-for="item in cityList"
        :key="item.name"
        :label="item.name"
        :value="item">
      </el-option>
    </el-select>
  </div>
  <div class='areaItem'>
    <el-select
      v-model="district"
      @change="choseDistrict"
      value-key="name"
      placeholder="区级地区">
      <el-option
        v-for="item in districtList"
        :key="item.id"
        :label="item.name"
        :value="item">
      </el-option>
    </el-select>
  </div>
    <div class='areaItem'>
      <el-select
      v-model="area"
      value-key="name"
      @change="choseBlock"
      placeholder="村级地区">
      <el-option
        v-for="item in areaList"
        :key="item.id"
        :label="item.name"
        :value="item">
      </el-option>
    </el-select>
    </div>
    <slot></slot>
</div>
</template>

<style lang="less" scoped>
.city_district_village{
  .areaItem{
    /deep/  .el-input__inner{
      width: 150px;
    }
    /deep/ .detail-address .el-input__inner{
      width: 290px ;
    }
    /deep/ .el-select{
      width:150px;
      margin-right:42px;
    }
    /deep/ .el-input{
      width:150px;
    }
  }
}

</style>
