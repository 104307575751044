<template>
  <div class="flex">
    <el-upload
      class="avatar-uploader"
      name="files"
      :action="importFileUrl"
      :headers="header"
      :data="param"
      :on-success="handleAvatarSuccess"
      :on-exceed="handleEaceed"
      :before-upload="beforeAvatarUpload"
      :on-remove="handleRemove"
      list-type="picture-card"
      :limit="limit"
      :file-list="fileList"
      :on-preview="handlePictureCardPreview"
      :auto-upload="true"
      accept="image/jpg, image/jpeg, image/png, image/PNG"
    >
      <i class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { Message, Dialog } from "element-ui";
import config from '../../utils/config.js'

Vue.use(Dialog);
export default {
  props: ["moduleList", "fileData"],
  data() {
    return {
      header: { headers: "multipart/form-data" },
      importFileUrl: `${config.baseUrl}/api/file/open/upload-file`,
      imageUrl: [],
      limit: 5,
      param: {
        module: this.moduleList.module,
        subModule: this.moduleList.subModule
      },
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: []
    };
  },
  methods: {
    // 上传图片
    handleAvatarSuccess(res, file, fileList) {
      const files = [];
      if (fileList.length > 0) {
        fileList.forEach((item, index) => {
          if (item.response.code === "200") {
            files.push(item.response.result[0]);
          }
        });
      }
      this.$emit("changeImgList", files);
    },
    // 上传图片已达到上限
    handleEaceed() {
      Message({
        type: "error",
        message: "上传图片已达到上限"
      });
    },
    handlePictureCardPreview(file, fileList) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //   删除图片
    handleRemove(file, fileList) {
      const files = [];
      if (fileList.length > 0) {
        fileList.forEach((item, index) => {
          if (item.response.code === "200") {
            files.push(item.response.result[0]);
          }
        });
      }
      this.$emit("changeImgList", files);
    },
    //   上传图片之前
    beforeAvatarUpload(file) {
      const types = ["image/jpeg", "image/gif", "image/bmp", "image/png"];
      const isImage = types.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        Message({
          type: "error",
          message: "上传图片只能是 JPG 和 PNG 格式!"
        });
      }
      if (!isLt2M) {
        Message({
          type: "error",
          message: "上传图片大小不能超过 2MB!"
        });
      }
      return isImage && isLt2M;
    }
  },
  // watch: {
  //   fileData: {
  //     handler(val) {
  //       val &&
  //         val.forEach((item, index) => {
  //           if (item.filePath) {
  //             this.fileList.push({
  //               name: item.fileId,
  //               url: item.filePath
  //             });
  //             for (let i = this.fileList.length - 1; i > 0; i--) {
  //               if (this.fileList[i].name === this.fileList[i - 1].name) {
  //                 this.fileList.splice(i, 1);
  //               }
  //             }
  //           }
  //         });
  //     },
  //     deep: true
  //   }
  // }
};
</script>
<style lang='less' scoped>
.avatar-uploader /deep/ .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
}
.avatar-uploader /deep/ .avatar-uploader {
  display: flex;
}
.avatar-uploader /deep/ .avatar-uploader-icon {
  text-align: center;
  line-height: 120px;
  margin: 0 auto;
}
.avatar-uploader {
  display: flex;
  /deep/ .el-upload--picture-card {
    width: 120px;
    height: 120px;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 120px;
    height: 120px;
  }
}
.imgList {
  display: inline-block;
  img {
    margin-right: 10px;
  }
}
.avatar {
  width: 178px;
  height: 178px;
}
</style>

