<template>
    <div class='contentData'>
        <el-form :model="data" :rules='rules'  ref="personRecord" label-width="140px" class="demo-data">
            <div class='title clearfix'><span class='first fl'></span><span class='second fl'>主体信息</span></div>
            <div class='flex dataCommon'>
                <el-form-item label="单位名称" prop="name" required>
                   <!-- <uploadImg @changeImg='changeImg'></uploadImg> -->
                   <el-input v-model="data.name" maxlength='30' clearable></el-input>
                </el-form-item>
                <!--   -->
                <el-form-item label="社会信用码" prop="societyCode" required>
                    <el-input v-model="data.societyCode" maxlength='30' clearable></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="法人" prop="legalPerson" required>
                   <!-- <uploadImg @changeImg='changeImg'></uploadImg> -->
                   <el-input v-model="data.legalPerson" maxlength='30' clearable></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone" required>
                    <el-input v-model="data.phone" maxlength='11' clearable></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="南繁人员姓名" prop="personName" required>
                   <!-- <uploadImg @changeImg='changeImg'></uploadImg> -->
                   <el-input v-model="data.personName" placeholder="请输入南繁人员姓名" maxlength='30' clearable></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="identityCard" required>
                    <el-input v-model="data.identityCard" maxlength='30' clearable></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="所在组别" prop="groupId" required>
                    <el-select v-model="data.groupId" placeholder="请选择" @change="choseGroup($event)">
                        <el-option
                        v-for="item in group_list"
                        :key="item.id"
                        :label="item.shortName"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="角色" prop="roleId" required>
                    <el-select v-model="data.roleId" placeholder="请选择">
                        <el-option
                        v-for="item in role_list"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            
            
            <div class='flex dataCommon'>
                <el-form-item label="外省地址" prop="otherDistrictId" required>
                    <addressSelect @addressInfo='addressInfo' :address="labAddress" ></addressSelect>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="南繁住址" prop="address" required>
                    <el-input v-model="data.address" type="tel" maxlength='11' clearable></el-input>
                </el-form-item>
                <el-form-item label="南繁人数" prop="peopleCount" required>
                    <el-input v-model="data.peopleCount" type="tel" maxlength='11' clearable></el-input>
                </el-form-item>
            </div>
            <div class='title clearfix'><span class='first fl'></span><span class='second fl'>基础信息</span></div>
            <el-form-item label="基地地址" prop="baseReq.cityId" required>
                <villageAddressSelect @villageInfo="villageInfo" :cityDistrictVillage='cityDistrictVillage'></villageAddressSelect>
            </el-form-item>
            <div class='flex dataCommon'>
                <el-form-item label="基地名称" prop="baseReq.id" required>
                    <el-select v-if='!baseFalg' v-model="data.baseReq.id" placeholder="请选择" @change="baseConfirm($event)">
                        <el-option
                        v-for="item in base_columns"
                        :key="item.id"
                        :label="item.baseName"
                        :value="item.id">
                        </el-option>
                    </el-select>
                     <el-input v-else v-model="addBaseInfo.baseName" clearable></el-input>
                </el-form-item>
                <el-form-item label="南繁时间" prop="nanfanTime" required>
                   <el-date-picker
                          v-model="data.nanfanTime"
                          @change='changNanfanTime($event)'
                          type="date"
                          format="yyyy-MM-dd"
                          placeholder="选择日期">
                        </el-date-picker>
                </el-form-item>
            </div>
            <div class='addBase'>
                <span>* 如若没有相关基地，可以点此进行操作 </span>
                <span @click="addBase">新增基地</span>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="面积(亩)" prop="baseReq.area" required>
                    <el-input v-model="data.baseReq.area" type='number' clearable></el-input>
                </el-form-item>
            </div>
            <div class='contentBg'>
              <div v-for='(item, index) in breeding_list' :key='index'>
                <div class="breedsTitle flex">
                  <span class='flex_length'>育种类型 ({{ index + 1 }})</span>
                  <div class='operation'>
                    <span class='delete' v-if="breeding_list.length > 1" @click="deleteBreeding(index)">删除</span>
                    <span class='add_breed'  @click="addBreeding">新增</span>
                  </div>
                </div>
                <div class='flex dataCommon'>
                   <el-form-item label="南繁品种" prop="nanfanVariety">
                        <el-input v-model="item.nanfanVariety" maxlength="32" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="南繁类型" prop="nanfanType">
                        <el-select v-model="item.nanfanType" placeholder="请选择">
                            <el-option
                            v-for="item in type_actions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class='flex dataCommon'>
                  <el-form-item label="南繁方向" prop="nanfanDirection">
                    <el-select v-model="item.nanfanDirection" placeholder="请选择">
                        <el-option
                        v-for="item in direction_actions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                        </el-option>
                    </el-select>
                    </el-form-item>
                    <el-form-item label="转基因管理" prop="transgenicManagement">
                        <el-input v-model="item.transgenicManagement" clearable></el-input>
                    </el-form-item>
                </div>
              </div>
            </div>
            <div class='title clearfix'><span class='first fl'></span><span class='second fl'>固定基地</span></div>
            <div class='flex dataCommon'>
                <el-form-item label="平面照片" prop="planeImgUrl">
                    <uploadMutImg :moduleList='moduleList' ref='planeImgUrl'  @changeImgList='changePlaneImgUrl'></uploadMutImg>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="地平面地图" prop="landMapUrl">
                    <uploadMutImg :moduleList='moduleList' ref='landMapUrl' @changeImgList='changeLandMapUrl'></uploadMutImg>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="土地流转合同" prop="landTransferContract">
                    <uploadMutImg :moduleList='moduleList' ref='landTransferContract' @changeImgList='changelandTransferContract'></uploadMutImg>
                </el-form-item>
            </div>
            <div class='title clearfix'><span class='first fl'></span><span class='second fl'>生产设施</span></div>
            
            <div class='flex dataCommon'>
                <el-form-item label="建筑物坐标-经度" prop="buildingLon">
                    <el-input v-model="data.baseReq.buildingLon" clearable></el-input>
                </el-form-item>
                <el-form-item label="建筑物坐标-纬度" prop="buildingLat">
                    <el-input v-model="data.baseReq.buildingLat" clearable></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="建筑面积(m²)" prop="buildingArea">
                     <el-input v-model="data.baseReq.buildingArea" clearable></el-input>
                </el-form-item>
                <el-form-item label="建设时间" prop="buildingTime">
                    <el-date-picker
                          v-model="data.baseReq.buildingTime"
                          @change='changNuildingTime($event)'
                          type="date"
                          format="yyyy-MM-dd"
                          placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="建筑类型" prop="buildingType">
                    <el-input v-model="data.baseReq.buildingType"  clearable></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="审批手续" prop="moduleList">
                    <uploadMutImg :moduleList='moduleList'  @changeImgList='changeImgList'></uploadMutImg>
                </el-form-item>
            </div>
            <div class='submit'>
              <el-button class='submitData' @click='addSubmit()'>提交</el-button>
            </div>
        </el-form>
        <el-dialog title="新增基地"  :visible.sync="dialogFormVisible">
            <el-form  :model="addBaseInfo" :rules='baseInfoRules' ref='addBaseInfo'>
                <el-form-item label="基地名称" prop='baseName' :label-width="formLabelWidth" required>
                    <el-input v-model="addBaseInfo.baseName" maxlength="10" clearable></el-input>
                </el-form-item>
                <el-form-item label="所属单位" prop='company' :label-width="formLabelWidth" required>
                    <el-input v-model="addBaseInfo.company" maxlength="10" clearable></el-input>
                </el-form-item>
                <el-form-item label="负责人" prop='person' :label-width="formLabelWidth" required>
                    <el-input v-model="addBaseInfo.person" maxlength="10" clearable></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop='personPhone' :label-width="formLabelWidth" required>
                    <el-input v-model="addBaseInfo.personPhone" clearable maxlength="11"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSureBase">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Vue from 'vue';
import { Upload, Loading, Message, DatePicker, Dialog, MessageBox, Alert } from 'element-ui';
import villageAddressSelect from '@/component/villageAddressSelect/index.vue';
import addressSelect from '@/component/addressSelect/index.vue';
import { yyyymmdd, filter_empty_value } from '@/utils/utils';
import uploadMutImg from '@/component/uploadMutImg/index.vue';
Vue.use(Upload);
Vue.use(Loading);
Vue.use(DatePicker);
Vue.use(Dialog);
 const validateName = (rule, value, callback) => {
     console.log(rule);
     let msg = '';
     if (rule.field === 'name') {
         msg = '请输入公司名称';
     } else if (rule.field === 'legalPerson') {
        msg = '请输入法人';
     } else if (rule.field === 'personName') {
        msg = '请输入南繁人员姓名';
     }
    if (!value) {
        callback(new Error(msg));
        return;
    }
    const reg = /^[a-zA-Z\u4e00-\u9fa5]+$/;
    if (!reg.test(value)) {
        callback(new Error('只能输入汉字或者英文'));
    }
    callback();
};
const validateIdentityCard = (rule, value, callback) => {
    if (!value) {
        callback(new Error('请输入身份证号'));
        return;
    }
    const reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (!reg.test(value)) {
        callback(new Error('请输入正确的身份证号'));
    }
    callback();
};
const validatePhone = (rule, value, callback) => {
    if (!value) {
        callback(new Error('请输入手机号码'));
        return;
    }
    const reg = /^1[3456789]\d{9}$/;
    if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号码'));
    }
    callback();
};
const validateAddress = (rule, value, callback) => {
    if (!value) {
        callback(new Error('请输入南繁地址'));
        return;
    }
    const reg = /^[a-zA-Z\u4e00-\u9fa5][a-zA-Z0-9\u4e00-\u9fa5]*$/;
    if (!reg.test(value)) {
        callback(new Error('只能输入中英文和数字, 且数字不能开头'));
    } 
    callback();
};
const validateArea = (rule, value, callback) => {
    if (!value) {
        callback(new Error('请输入面积'));
        return;
    }
    const reg = /^\d*\.{0,1}\d{0,2}$/;
    if (value <= 0) {
        callback(new Error('面积不能小于0'));
        return;
    } 
    callback();
};
const baseReqVillageId = (rule, value, callback) => {
    if (!value) {
        callback(new Error('请选择基地地址'));
        return;
    }
    callback();
};
const validateBaseName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入基地名称'));
        return;
      }
      const reg = /^[a-zA-Z\u4e00-\u9fa5][a-zA-Z0-9\u4e00-\u9fa5]*$/;
      if (!reg.test(value)) {
        callback(new Error('只能输入中英文和数字, 且数字不能开头'));
        return;
      }
      callback();
};
const societyCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入社会公用信用码'));
        return;
      }
      const reg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;
      if (!reg.test(value)) {
        callback(new Error('请输入正确的社会公用信用码'));
        return;
      }
      callback();
};
const peopleCount = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入南繁人数'));
        return;
      }
     const reg = /^[1-9]+[0-9]*$/;
      if (!reg.test(value)) {
        callback(new Error('只能输入正整数'));
        return;
      }
      callback();
};
export default {
    components: {
        villageAddressSelect,
        addressSelect,
        uploadMutImg
    },
    data() {
        return {
             rules: {
                name: [{
                    required: true, validator: validateName
                }],
                identityCard: [
                    { required: true, validator: validateIdentityCard }
                ],
                phone: [
                    { required: true, validator: validatePhone }
                ],
                groupId: [
                    { required: true,  message: '请选择所在组别', trigger: 'blur' }
                ],
                roleId: [
                    { required: true,  message: '请选择角色', trigger: 'blur' }
                ],
                address: [
                    {required: true, validator: validateAddress}
                ],
                'baseReq.area': [
                    {validator: validateArea, required: true, trigger: 'blur'}
                ],
                otherDistrictId: [
                    { message: '请选择外省地址', required: true}
                ],
                'baseReq.cityId': [
                    { validator: baseReqVillageId, required: true}
                ],
                'baseReq.id': [
                    { message: '请选择基地', required: true}
                ],
                nanfanTime: [
                    { message: '请选择南繁时间', required: true}
                ],
                societyCode: [
                    { validator: societyCode, required: true}
                ],
                legalPerson: [
                    { validator: validateName, required: true}
                ],
                personName: [
                    { validator: validateName, required: true}
                ],
                peopleCount: [
                     { validator: peopleCount, required: true}
                ]
            },
            baseInfoRules: {
                baseName: [{
                    required: true, validator: validateBaseName
                }],
                company: [{
                    required: true, message: '请填写所属单位'
                }],
                person: [{
                    required: true, validator: validateName
                }],
                personPhone: [{
                    required: true, validator: validatePhone
                }]
            },
            data: {
                nature: 2,       // 1-个人，2-企业
                baseId: '',       // 基地id
                name: '',        // 姓名
                societyCode: '', // 社会信用码
                legalPerson: '',     // 法人
                personName: '',       // 南繁人员姓名             
                identityCard: '',   // 身份证号
                phone: '',                 // 联系电话
                groupId: '',       // 组别id
                roleId: '',     // 角色id
                otherProvinceId: '', // 外省 省id
                otherCityId: '',         // 外省 市id
                otherDistrictId: '', // 外省 区id
                address: '',             // 南繁地址
                nanfanTime: '',        // 南繁时间
                peopleCount: '',     // 南繁人数
                baseReq: {
                    id: '', // 基地id
                    nanfanAddress: '', // 基地名称
                    area: '',       // 面积
                    cityId: '',         // 市
                    districtId: '', // 区
                    villageId: '',    // 村
                    provinceId: '',
                    // 育种信息
                    breeds: [],

                    // 固定基地参数
                    planeImgUrl: '',             // 平面照片
                    landMapUrl: '',            // 地平面地图
                    landTransferContract: '', // 土地流转合同

                    // 生产设施参数
                    buildingLon: '',   // 建筑物坐标 X
                    buildingLat: '',   // 建筑物坐标 Y
                    buildingArea: '', // 建筑面积
                    buildingTime: '', // 建设时间
                    buildingType: '', // 建筑类型
                    approvalProcess: '' // 审批手续
                }
            },
            breeding_list: [{ // 育种类型列表
                nanfanVariety: '',       // 南繁品种
                nanfanType: '',          // 南繁类型
                nanfanDirection: '',     // 南繁方向
                transgenicManagement: '' // 转基因管理
            }],
            group_list: [],
            role_list: [],
            base_columns: [],
            labAddress: { 
                disabled: false,
                provinceId: '',
                cityId: '',
                districtId: ''
            },
            cityDistrictVillage: {
                disabled: false,
                cityId: "",
                districtId: "",
                villagesId: '',
                provinceId: ''
            },
            type_actions: [{ name: '加代', id: 1 }, { name: '繁育', id: 2 }, { name: '鉴定', id: 3 }],
            direction_actions: [{ name: '抗虫', id: 1 }, { name: '抗旱', id: 2 }],
            moduleList: {
                module: 'nanfanRecords',
                subModule: 'personRecord'
            },
            imgList: [], // 生产设施 图片
            planeImgUrlList: [], // 平面照片
            landMapUrlList: [], // 地平面地图
            landTransferContractList: [], // 土地流转合同
            dialogFormVisible: false,
            addBaseInfo: {
                baseName: '', // 基地名称
                company: '',   // 所属单位
                person: '',    // 负责人
                personPhone: '' // 负责人电话
            },
            formLabelWidth: '120px',
            baseFalg: false,
            timeOut: new Date(),
            submitStatus: true
        };
    },
    mounted() {
        this.getGroupList();
    },
    methods: {
        /** 获取备案组别 */
        async getGroupList() {
            const api = this.$fetchApi.nanfanRecordGroup.api;
            const data = await this.$fetchData.fetchPost({}, api, 'json');
            if (data.code === '200') {
                this.group_list = data.result;
            }
        },
        /** 根据组别获取角色 */
        async getRoleList(id) {
            const api = this.$fetchApi.getRoleById.api + '/' + id;
            const data = await this.$fetchData.fetchPost({}, api, 'json');
            if (data.code === '200') {
                this.role_list = JSON.parse(JSON.stringify(data.result));
            }
        },
        // 选择组别
        choseGroup(data) {
            this.getRoleList(data);
        },
        addressInfo(val) {
            this.data.otherProvinceId = val[0].id; // 居住地址 省id
            this.data.otherCityId = val[1].id;         // 居住地址 市id
            this.data.otherDistrictId = val[2].id; // 居住地址 区id
        },
        // 选择时间 时间处理
        changNanfanTime(data) {
            this.data.nanfanTime = yyyymmdd(data);
        },
        /** 返回市区村信息 */
        villageInfo(val) {
            this.data.baseReq.provinceId =  val[0].id ? val[0].id : '';
            this.data.baseReq.cityId = val[1].id ? val[1].id : '';
            this.data.baseReq.districtId = val[2].id  ? val[2].id : '';
            this.data.baseReq.villageId = val[3].id  ? val[3].id : '';
            this.baseFalg = false;
            const params = {
                pageNum: 1,
                pageSize: 1000,
                cityId: '',
                districtId: '',
                villageId: '',
                provinceId: ''
            };
            if (!val[0]) {
                params.provinceId = val[0].id ? val[0].id : '';
            } else if (!val[1]) {
                params.cityId = val[1].id ? val[1].id : '';
            } else if (!val[2]) {
                params.districtId = val[2].id  ? val[2].id : '';
            } else {
                params.villageId = val[3].id  ? val[3].id : '';
            }
            this.data.baseReq.id = '';
            this.getBaseList(params);
        },
        /** 获取基地列表 */
        async getBaseList(params) {
            const api = this.$fetchApi.baseScreeningContent.api;
            const data = await this.$fetchData.fetchPost(params, api, 'json');
            if (data.code === '200') {
                const obj = [{
                    baseId: '',
                    baseName: '暂无数据'
                }];
                this.base_columns = data.result ? data.result.rows : obj;
            }
        },
        baseConfirm(data) {
            this.base_columns.forEach((item, index) => {
                if (item.id === data){
                    this.data.baseReq.nanfanAddress = item.baseName;
                }
            });
            this.data.baseId = data;
            this.getBaseDetailInfo(data);
        },
         /** 新增基地 */
        addBase() {
            if (!this.data.baseReq.cityId) {
                Message('请先选择基地地址');
                return true;
            }
            this.dialogFormVisible = true;
        },
        /** 增加育种类型 */
        addBreeding() {
            this.breeding_list.push({
                nanfanVariety: '',       // 南繁品种
                nanfanType: '',          // 南繁类型
                nanfanDirection: '',     // 南繁方向
                transgenicManagement: '' // 转基因管理
            });
        },
        /** 删除育种类型 */
        deleteBreeding(index) {
            this.breeding_list.splice(index, 1);
        },
        /** 获取基地具体信息 */
        async getBaseDetailInfo(id) {
            const api = this.$fetchApi.nanfanBaseDetailInfo.api + id;
            const data = await this.$fetchData.fetchPost({}, api, 'json');
            if (data.code === '200') {
                if (data.result.breeds && data.result.breeds.length > 0) {
                this.breeding_list = [];
                data.result.breeds.forEach((item, index) => {
                    this.breeding_list.push({
                    nanfanVariety: item.plantName || '',   // 南繁品种
                    nanfanType: item.breedProject || '',   // 南繁类型
                    nanfanDirection: item.direction || '', // 南繁方向
                    transgenicManagement: item.transgenicManagement || '' // 转基因管理
                    });
                });
                }
            }
        },
        // 建设时间
        changNuildingTime(data) {
             this.data.baseReq.buildingTime = yyyymmdd(data);
        },
        // 获取图片
        getImgList(fileList) {
            const files = [];
            if (fileList.length > 0) {
               fileList.forEach(item => {
                   files.push({
                    url: item.filePath,
                    name: item.name
                });
               });
            }
            return files;
        },
        // 平面照片
        changePlaneImgUrl(fileList) {
            this.planeImgUrlList = this.getImgList(fileList);
        },
        // 地平面地图（旱地、水田、大棚）
        changeLandMapUrl(fileList) {
            this.landMapUrlList = this.getImgList(fileList);
        },
        // 土地流转合同
        changelandTransferContract(fileList) {
            this.landTransferContractList = this.getImgList(fileList);
        },
        // 生产设施
        changeImgList(fileList) {
            this.imgList = this.getImgList(fileList);
        },
        // 新增地址
        onSureBase() {
            if (new Date() - this.timeOut < 1000) {
                return true;
            }
            this.timeOut = new Date();
            this.$refs.addBaseInfo.validate((valid) => {
                if (valid) {
                    this.addBaseCallback();
                } else {
                    window.scroll(0, 400);
                    return false;
                }
            });
        },
        async addBaseCallback() {
            const loadingInstance = Loading.service({
                fullscreen: true,
                background: 'rgba(0,0,0,.4)'
            });
            const params = JSON.parse(JSON.stringify(this.addBaseInfo));
            params.cityId = this.data.baseReq.cityId;
            params.districtId = this.data.baseReq.districtId;
            params.villageId = this.data.baseReq.villageId;
            const api = this.$fetchApi.nanfanRecordAddBse.api;
            const data = await this.$fetchData.fetchPost(params, api, 'json');
            this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close();
            });
            if (data.code === '200') {
                this.dialogFormVisible = false;
                this.data.baseId = data.result.id;
                this.data.baseReq.nanfanAddress = this.addBaseInfo.baseName;
                this.baseFalg = true;
            }
        },
        /** 新增完成 */
        addSubmit() {
            if (!this.submitStatus) {
                return;
            }
            this.$refs.personRecord.validate((valid) => {
                if (valid) {
                    this.judgeBlackInfo();
                } else {
                    window.scroll(0, 400);
                    return false;
                }
            });
        },
        async addRecord() {
            const loadingInstance = Loading.service({
                fullscreen: true,
                background: 'rgba(0,0,0,.4)'
            });
            const params = JSON.parse(JSON.stringify(this.data));
            params.baseReq.breeds = JSON.parse(JSON.stringify(this.breeding_list));
            params.baseReq.planeImgUrl = JSON.stringify(this.planeImgUrlList);
            params.baseReq.landMapUrl = JSON.stringify(this.landMapUrlList);
            params.baseReq.landTransferContract = JSON.stringify(this.landTransferContractList);
            params.baseReq.approvalProcess = JSON.stringify(this.imgList);
          // 育种信息
            const api = this.$fetchApi.nanfanRecordSubmit.api;
            const data = await this.$fetchData.fetchPost(filter_empty_value(params), api, 'json');
            this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close();
            });
            if (data.code === '200') {
                this.submitStatus = false;
                this.$emit('changeNature', 2);
                 MessageBox.confirm('管理人员在5个工作日内对登记备案内容进行核实，请耐心等待！', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success'
                });
            }
        },
        // 判断是否是黑名单
        async judgeBlackInfo() {
            const api = this.$fetchApi.judgeBlackInfo.api;
            const data = await this.$fetchData.fetchPost({}, api, "json");
            if (data.code === '200') {
                if (data.result === 1) {
                    this.checkModuleData();
                } else if (data.result === 0) {
                    Message('你在黑名单内,暂不能进入服务');
                }
            }
        },
        // 判断备案时间是否开启
        async checkModuleData() {
            const checkModuleApi = this.$fetchApi.checkModule.api + '/be9b8aafd42546e1a5ee86b34e16cdd6';
            const checkModuleData = await this.$fetchData.fetchPost({}, checkModuleApi, "json");
            if (checkModuleData.code === '200') {
                if (checkModuleData.result.status === 0) {
                    this.addRecord();
                } else if (checkModuleData.result.status === 1) {
                    Message('后台已关闭');
                } else if (checkModuleData.result.status === 2) {
                    Message('现在不在备案时间');
                }
            }
        }

    }
};
</script>
<style lang="less" scoped>
     @import './common.less';
     .source{
      width: 1140px;
      background-color: #b4d1ff;
      border-radius: 4px;
      font-size: 18px;
      color: #448afc;
      padding: 20px;
      margin: 0 auto;
    }
    .operation{
      span{
        width: 80px;
        height: 36px;
        background-color: #ff5454;
        border-radius: 2px;
        display: inline-block;
        color:#fff;
        line-height: 36px;
        text-align: center;
      }
      .add_breed{
        background-color: #448afc;
        margin-left:20px;
      }
      
    }
    .breedsTitle{
        padding: 20px 110px 10px 30px;
      }
    .contentBg{
        width:1135px;
        background-color: #f6f6f6;
        border-radius: 4px;
        margin: 0 auto;
        padding-bottom:20px;
      }
      .padding{
        margin-top:20px;
      }
      .addBase{
        padding: 10px 0 20px;
        margin-left:70px;
        color: #666;
        span:nth-child(2){
            color: #448afc;
        }
      }
</style>