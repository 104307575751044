<template>
    <div>
        <nf-breadcrumb :breadList='breadList'></nf-breadcrumb>
        <div class='tabContent'>
            <div class='tab flex'>
                <div @click='handleClick(0)' :class='activeName==0 ?"active":""'>个人备案</div>
                <div @click='handleClick(1)' :class='activeName==1 ?"active":""'>公司备案</div>
            </div>
             <personRecord v-if='activeName==0' @changeNature='changeNature'></personRecord>
             <companyRecord v-if='activeName==1' @changeNature='changeNature'></companyRecord>
        </div>
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import companyRecord from './components/companyRecord.vue';
import personRecord from './components/personRecord.vue';
import Vue from 'vue';
import { Message } from 'element-ui';
export default {
    components: {
        nfBreadcrumb,
        companyRecord,
        personRecord
    },
    name: 'nanfanRecords',
    data() {
        return {
            breadList: [{
                name: '南繁申请'
            }],
            activeName: 0,
            nature: 0,
            timeOut: new Date()
        };
    },
    created() {
        this.getRecordStatus();
    },
    mounted() {
        this.$store.commit("addCache", 'nanfanRecords');
        
    },
    methods: {
        async getRecordStatus() {
            const api = this.$fetchApi.getRecordNature.api;
            const data = await this.$fetchData.fetchPost({}, api, 'json');
            if (data.code === '200') {
                this.nature = data.result.nature;
                if (data.result.nature === 0 || data.result.nature === 1) {
                    this.activeName = 0;
                }
                if (data.result.nature === 2) {
                    this.activeName = 1;
                }
            }
        },
        handleClick(data) {
            if (this.nature === 0) {
                this.activeName = data;
                return;
            }
            if (new Date() - this.timeOut < 1500) {
                return true;
            }
            this.timeOut = new Date();
            //  1个人
            if (this.nature === 1) {
                if (data === 1) {
                    Message({
                        message: '企业备案不可于个人备案同时填写',
                        duration: 1500
                    });
                    return true;
                }
            }
            // 2企业
            if (this.nature === 2) {
                if (data === 0) {
                    Message({
                        message: '个人备案不可于企业备案同时填写',
                        duration: 1500
                    });
                    return true;
                }    
            }
            this.activeName = data;
            
        },
        changeNature(data) {
            this.nature = data;
        }
    }
};
</script>
<style lang="less" scoped>
    .tab{
        position: relative;
        div{
             width: 130px;
            height: 46px;
            text-align: center;
            line-height: 46px;
            padding:0;
            cursor: pointer;
            border-bottom: 2px solid #fff;
        }
        .active{
            border-bottom: 2px solid #409EFF;
            position: relative;
            z-index:10;
            color:#409EFF;
        }
    }
    .tab::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #E4E7ED;
        z-index: 1;
    }
    .tabContent{
        position: relative;
        width:1180px;
        margin: 0 auto;
    }
     .myDataShare{
        width: 128px;
        height: 36px;
        background-color: #265ae1;
        border-radius: 2px;
        color: #fff;
        line-height: 36px;
        text-align: center;
        font-size: 16px;
        padding:0;
        outline: none;
        border: none;
        margin-bottom:18px;
        position: absolute;
        right:0;
        top:5px;
    }
</style>